import React from "react";
import { useTranslation } from "react-i18next";

import { HiOutlineArrowSmRight } from "react-icons/hi";
import teamPhoto from "../../../image/team.jpg";

export default function About() {
  const [t, i18n] = useTranslation("global");
  return (
    <section id="about">
      <div className="container">
        <div className="left-about">
          <div className="title" data-aos="fade-up">
            <h2 className="heading">{t("heros.about.title")}</h2>
            <p>{t("heros.about.paragraph1")}</p>
            <p>{t("heros.about.paragraph2")}</p>
            <p>{t("heros.about.paragraph3")}</p>
            <p>
              {t("heros.about.paragraph4.1")}
              <span>{t("heros.about.regularschool")}</span>
              {t("heros.about.paragraph4.2")}
            </p>
          </div>
          <div className="video" data-aos="fade-up">
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="42"
              height="42"
              viewBox="0 0 42 42"
              fill="none"
            >
              <g clipPath="url(#clip0_334_42)">
                <path
                  d="M20.918 0C16.7808 0 12.7365 1.22682 9.29658 3.52531C5.85663 5.82381 3.17553 9.09075 1.59229 12.913C0.00906304 16.7353 -0.405182 20.9412 0.401942 24.9989C1.20907 29.0566 3.20131 32.7838 6.12674 35.7092C9.05217 38.6346 12.7794 40.6269 16.8371 41.434C20.8948 42.2411 25.1007 41.8269 28.9229 40.2437C32.7452 38.6604 36.0121 35.9793 38.3106 32.5394C40.6091 29.0994 41.8359 25.0552 41.8359 20.918C41.8251 15.3735 39.6178 10.0592 35.6972 6.1387C31.7767 2.21817 26.4624 0.0108396 20.918 0ZM20.918 39.375C17.2675 39.375 13.699 38.2925 10.6638 36.2644C7.62856 34.2363 5.26287 31.3538 3.8659 27.9812C2.46894 24.6086 2.10342 20.8975 2.81559 17.3172C3.52776 13.7369 5.28562 10.4481 7.86689 7.86688C10.4481 5.28561 13.7369 3.52775 17.3172 2.81558C20.8975 2.10342 24.6086 2.46893 27.9812 3.8659C31.3538 5.26286 34.2364 7.62855 36.2644 10.6638C38.2925 13.699 39.375 17.2675 39.375 20.918C39.3696 25.8114 37.4233 30.5029 33.9631 33.9631C30.5029 37.4233 25.8114 39.3696 20.918 39.375ZM28.1572 19.8926L18.3135 13.3301C18.1281 13.208 17.9132 13.1384 17.6915 13.1285C17.4698 13.1186 17.2495 13.1689 17.0541 13.274C16.8586 13.379 16.6952 13.5351 16.5811 13.7254C16.4671 13.9158 16.4067 14.1335 16.4063 14.3555V27.4805C16.4065 27.7042 16.4677 27.9236 16.5834 28.1151C16.699 28.3066 16.8646 28.463 17.0625 28.5674C17.2568 28.6722 17.4761 28.7219 17.6966 28.7111C17.9171 28.7002 18.1304 28.6293 18.3135 28.5059L28.1572 21.9434C28.3264 21.8311 28.4652 21.6787 28.5613 21.4998C28.6573 21.3209 28.7075 21.121 28.7075 20.918C28.7075 20.7149 28.6573 20.515 28.5613 20.3361C28.4652 20.1572 28.3264 20.0048 28.1572 19.8926ZM18.8672 25.1836V16.6523L25.2656 20.918L18.8672 25.1836Z"
                  fill="#2C2C2C"
                />
              </g>
              <defs>
                <clipPath id="clip0_334_42">
                  <rect width="42" height="42" fill="white" />
                </clipPath>
              </defs>
            </svg>
            <div className="content">
              <h3>
                <span>{t("heros.about.regular")}</span>
                {t("heros.about.video1")}
              </h3>
              <h3 className="video-title">
                {t("heros.about.video2")} <HiOutlineArrowSmRight />
              </h3>
            </div>
          </div>
          <div className="teamPhoto" data-aos="fade-up">
            <img src={teamPhoto} alt=" " />
            <div className="shadow"></div>
          </div>
        </div>

        <div className="connection">
          <div
            className={`card ${i18n.language == "ru" ? "ru" : ""}`}
            data-aos="flip-right"
          >
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="38"
              height="37"
              viewBox="0 0 38 37"
              fill="none"
            >
              <path
                d="M3.8 0C1.691 0 0 1.73316 0 3.89474V23.3684C0 24.4014 0.400356 25.392 1.11299 26.1224C1.82563 26.8528 2.79218 27.2632 3.8 27.2632H19V37L24.7 31.1579L30.4 37V27.2632H34.2C35.2078 27.2632 36.1744 26.8528 36.887 26.1224C37.5996 25.392 38 24.4014 38 23.3684V3.89474C38 2.86179 37.5996 1.87115 36.887 1.14074C36.1744 0.410337 35.2078 0 34.2 0H3.8ZM19 3.89474L24.7 7.78947L30.4 3.89474V10.7105L36.1 13.6316L30.4 16.5526V23.3684L24.7 19.4737L19 23.3684V16.5526L13.3 13.6316L19 10.7105V3.89474ZM3.8 3.89474H13.3V7.78947H3.8V3.89474ZM3.8 11.6842H9.5V15.5789H3.8V11.6842ZM3.8 19.4737H13.3V23.3684H3.8V19.4737Z"
                fill="#0DD70D"
              />
            </svg>
            <h3>{t("heros.about.advantages.certificate.title")}</h3>
            <p>{t("heros.about.advantages.certificate.body")}</p>
          </div>
          <div
            className={`card ${i18n.language == "ru" ? "ru" : ""}`}
            data-aos="flip-right"
          >
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="42"
              height="40"
              viewBox="0 0 42 40"
              fill="none"
            >
              <path
                d="M2 2H36C36 2 40 4 40 9C40 14 36 16 36 16H2C2 16 6 14 6 9C6 4 2 2 2 2ZM40 24H6C6 24 2 26 2 31C2 36 6 38 6 38H40C40 38 36 36 36 31C36 26 40 24 40 24Z"
                stroke="#0DD70D"
                strokeWidth="4"
                strokeLinecap="round"
                strokeLinejoin="round"
              />
            </svg>
            <h3>{t("heros.about.advantages.grade.title")}</h3>
            <p>{t("heros.about.advantages.grade.body")}</p>
          </div>
          <div
            className={`card ${i18n.language == "ru" ? "ru" : ""}`}
            data-aos="flip-right"
          >
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="47"
              height="38"
              viewBox="0 0 47 38"
              fill="none"
            >
              <path
                d="M6.34321 31.6667C5.18029 31.6667 4.18511 31.2536 3.35767 30.4274C2.52883 29.5999 2.1144 28.6056 2.1144 27.4444V4.22222C2.1144 3.06111 2.52883 2.06678 3.35767 1.23922C4.18511 0.413074 5.18029 0 6.34321 0H40.1736C41.3366 0 42.3325 0.413074 43.1613 1.23922C43.9887 2.06678 44.4025 3.06111 44.4025 4.22222V27.4444C44.4025 28.6056 43.9887 29.5999 43.1613 30.4274C42.3325 31.2536 41.3366 31.6667 40.1736 31.6667H6.34321ZM6.34321 27.4444H40.1736V4.22222H6.34321V27.4444ZM2.1144 38C1.51532 38 1.0135 37.7973 0.608948 37.392C0.202983 36.9881 0 36.487 0 35.8889C0 35.2907 0.202983 34.7897 0.608948 34.3858C1.0135 33.9804 1.51532 33.7778 2.1144 33.7778H44.4025C45.0015 33.7778 45.5034 33.9804 45.9079 34.3858C46.3139 34.7897 46.5169 35.2907 46.5169 35.8889C46.5169 36.487 46.3139 36.9881 45.9079 37.392C45.5034 37.7973 45.0015 38 44.4025 38H2.1144ZM6.34321 27.4444V4.22222V27.4444Z"
                fill="#0DD70D"
              />
            </svg>
            <h3>{t("heros.about.advantages.coworking.title")}</h3>
            <p>{t("heros.about.advantages.coworking.body")}</p>
          </div>
          <div
            className={`card ${i18n.language == "ru" ? "ru" : ""}`}
            data-aos="flip-right"
          >
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="43"
              height="42"
              viewBox="0 0 43 42"
              fill="none"
            >
              <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M0 0H36.818V5.83286H34.4426V2.33315H2.37536V29.1643H27.7762V31.4975H0V0ZM35.6303 15.1654C36.5753 15.1654 37.4816 14.7967 38.1498 14.1404C38.818 13.4841 39.1934 12.5939 39.1934 11.6657C39.1934 10.7375 38.818 9.84738 38.1498 9.19105C37.4816 8.53473 36.5753 8.16601 35.6303 8.16601C34.6853 8.16601 33.7791 8.53473 33.1109 9.19105C32.4427 9.84738 32.0673 10.7375 32.0673 11.6657C32.0673 12.5939 32.4427 13.4841 33.1109 14.1404C33.7791 14.7967 34.6853 15.1654 35.6303 15.1654ZM38.0425 17.5103C39.5853 17.5103 40.8062 18.1915 41.6055 19.2438C42.3526 20.2295 42.6685 21.4486 42.7398 22.5744C42.8096 23.7291 42.6494 24.8862 42.2683 25.9807C41.912 26.9957 41.3098 28.0456 40.381 28.7654V40.2468C40.3819 40.6864 40.2142 41.1103 39.9114 41.434C39.6086 41.7578 39.1928 41.9577 38.7467 41.994C38.3006 42.0303 37.857 41.9004 37.504 41.63C37.151 41.3597 36.9146 40.9687 36.8418 40.5349L35.3097 31.4975H34.9557L33.2241 40.5687C33.1419 40.9969 32.9 41.3795 32.5458 41.6417C32.1916 41.904 31.7505 42.027 31.3088 41.9867C30.8672 41.9465 30.4567 41.7458 30.1576 41.424C29.8586 41.1022 29.6925 40.6824 29.6919 40.2468V23.6033C29.4544 23.9586 29.2201 24.316 28.9888 24.6754L28.8962 24.8188L28.8724 24.8562L28.8665 24.8667C28.7064 25.1191 28.4835 25.3273 28.2188 25.4716C27.954 25.6159 27.6562 25.6916 27.3534 25.6914H21.415C20.9425 25.6914 20.4894 25.5071 20.1553 25.1789C19.8212 24.8508 19.6335 24.4057 19.6335 23.9416C19.6335 23.4775 19.8212 23.0324 20.1553 22.7042C20.4894 22.3761 20.9425 22.1917 21.415 22.1917H26.3759C26.6645 21.7531 27.0446 21.1838 27.4437 20.6122C27.8594 20.0161 28.3178 19.3849 28.7264 18.8903C28.9247 18.6488 29.1468 18.3969 29.3701 18.1904C29.4793 18.0889 29.6314 17.9571 29.8166 17.8404C30.1436 17.6296 30.5253 17.5154 30.9164 17.5114H38.0425V17.5103Z"
                fill="#0DD70D"
              />
            </svg>
            <h3>{t("heros.about.advantages.trainings.title")}</h3>
            <p>{t("heros.about.advantages.trainings.body")}</p>
          </div>
          <div
            className={`card ${i18n.language == "ru" ? "ru" : ""}`}
            data-aos="flip-right"
          >
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="43"
              height="45"
              viewBox="0 0 43 45"
              fill="none"
            >
              <g clipPath="url(#clip0_334_92)">
                <path
                  d="M7.16545 2C7.16545 2 2.15593 5.6989 2.34571 8.32967C2.50676 10.5619 6.80146 10.868 7.16545 13.0769C7.35724 14.2412 5.55887 16.2418 5.55887 16.2418C5.55887 16.2418 10.3786 13.7323 10.3786 11.4945C10.3786 9.25667 6.08101 8.92525 5.55887 6.74725C5.16978 5.12389 7.16545 2 7.16545 2ZM15.1984 2C15.1984 2 10.1888 5.6989 10.3786 8.32967C10.5397 10.5619 14.8344 10.868 15.1984 13.0769C15.3902 14.2412 13.5918 16.2418 13.5918 16.2418C13.5918 16.2418 18.4115 13.7323 18.4115 11.4945C18.4115 9.25667 14.1139 8.92525 13.5918 6.74725C13.2027 5.12389 15.1984 2 15.1984 2ZM23.2313 2C23.2313 2 18.2218 5.6989 18.4115 8.32967C18.5726 10.5619 22.8673 10.868 23.2313 13.0769C23.4231 14.2412 21.6247 16.2418 21.6247 16.2418C21.6247 16.2418 26.4445 13.7323 26.4445 11.4945C26.4445 9.25667 22.1468 8.92525 21.6247 6.74725C21.2356 5.12389 23.2313 2 23.2313 2ZM0.0656619 18.7143C0.20925 25.0388 1.96776 36.4603 7.5671 42.2527H22.8296C28.429 36.4603 30.1876 25.0388 30.3311 18.7143H0.0656619ZM32.157 18.7583C32.1383 19.6634 32.0915 20.6623 32.0092 21.731C34.8099 22.0929 36.9877 24.4646 36.9877 27.3187C36.9877 30.4216 34.4145 32.956 31.2642 32.956C30.8328 32.9557 30.4028 32.9071 29.9826 32.8112C29.6902 33.7626 29.3624 34.7066 28.9945 35.6308C29.7349 35.8254 30.4979 35.9236 31.2642 35.9231C36.0995 35.9231 40 32.0813 40 27.3187C40 22.853 36.5706 19.1969 32.1569 18.7583H32.157ZM-2.9999 44.033C-2.84858 44.5466 -2.61291 45.1407 -2.30154 45.652C-2.03675 46.0865 -1.7302 46.4637 -1.45527 46.6984C-1.18054 46.9328 -0.967873 47 -0.867462 47H31.2642C31.3646 47 31.5773 46.9329 31.8521 46.6984C32.127 46.4637 32.4335 46.0865 32.6984 45.652C33.0097 45.1407 33.2454 44.5466 33.3967 44.033H-3H-2.9999Z"
                  fill="#0DD70D"
                />
              </g>
              <defs>
                <clipPath id="clip0_334_92">
                  <rect width="43" height="45" fill="white" />
                </clipPath>
              </defs>
            </svg>
            <h3>{t("heros.about.advantages.coffee.title")}</h3>
            <p>{t("heros.about.advantages.coffee.body")}</p>
          </div>
          <div
            className={`card ${i18n.language == "ru" ? "ru" : ""}`}
            data-aos="flip-right"
          >
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="45"
              height="45"
              viewBox="0 0 46 46"
              fill="none"
            >
              <g clipPath="url(#clip0_901_4)">
                <path
                  d="M28.125 8.4375H16.875L12.709 2.18848C12.085 1.24805 12.7529 0 13.8779 0H31.1221C32.2471 0 32.915 1.24805 32.291 2.18848L28.125 8.4375ZM16.875 11.25H28.125C28.459 11.4697 28.8369 11.7158 29.2676 11.9883C34.251 15.1787 45 22.0518 45 36.5625C45 41.2207 41.2207 45 36.5625 45H8.4375C3.7793 45 0 41.2207 0 36.5625C0 22.0518 10.749 15.1787 15.7324 11.9883C16.1543 11.7158 16.541 11.4697 16.875 11.25ZM24.2578 18.9844C24.2578 18.0176 23.4668 17.2266 22.5 17.2266C21.5332 17.2266 20.7422 18.0176 20.7422 18.9844V20.2148C20.0742 20.3643 19.4062 20.6016 18.791 20.9619C17.5693 21.6914 16.5146 22.9658 16.5234 24.8203C16.5322 26.6045 17.5781 27.7295 18.6943 28.3975C19.6611 28.9775 20.8652 29.3467 21.8232 29.6279L21.9727 29.6719C23.0801 30.0059 23.8887 30.2695 24.4336 30.6123C24.8818 30.8936 24.9434 31.0869 24.9521 31.333C24.9609 31.7725 24.7939 32.0361 24.4336 32.2559C23.9941 32.5283 23.2998 32.6953 22.5527 32.6689C21.5771 32.6338 20.6631 32.3262 19.4678 31.9219C19.2656 31.8516 19.0547 31.7812 18.835 31.7109C17.9121 31.4033 16.9189 31.9043 16.6113 32.8184C16.3037 33.7324 16.8047 34.7344 17.7188 35.042C17.8857 35.0947 18.0703 35.1562 18.2549 35.2266C18.9844 35.4814 19.8281 35.7715 20.7334 35.9648V37.2656C20.7334 38.2324 21.5244 39.0234 22.4912 39.0234C23.458 39.0234 24.249 38.2324 24.249 37.2656V36.0527C24.9521 35.9033 25.6553 35.6572 26.2881 35.2617C27.5449 34.4795 28.4941 33.1436 28.4678 31.3066C28.4414 29.5225 27.4395 28.3711 26.3057 27.6504C25.2949 27.0176 24.0293 26.6309 23.0449 26.332L22.9834 26.3145C21.8584 25.9717 21.0586 25.7256 20.4961 25.3916C20.0391 25.1191 20.0303 24.9609 20.0303 24.8027C20.0303 24.4775 20.1533 24.2314 20.5752 23.9854C21.0498 23.7041 21.7705 23.5371 22.4648 23.5459C23.3086 23.5547 24.2402 23.7393 25.207 24.0029C26.1475 24.249 27.1055 23.6953 27.3603 22.7549C27.6152 21.8145 27.0527 20.8564 26.1123 20.6016C25.541 20.4521 24.9082 20.3027 24.2578 20.1885V18.9844Z"
                  fill="#0DD70D"
                />
              </g>
              <defs>
                <clipPath id="clip0_901_4">
                  <rect width="45" height="45" fill="white" />
                </clipPath>
              </defs>
            </svg>
            <h3>{t("heros.about.advantages.points.title")}</h3>
            <p>{t("heros.about.advantages.points.body")}</p>
          </div>
        </div>
      </div>
    </section>
  );
}
